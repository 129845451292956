import React from 'react';
import styled from 'styled-components';
import { Editor } from '@/features/editor';
import { useEditorLoader } from '@/features/editor/use-editor-loader';
import {
  EditorDeviceSection,
  EditorInspectorSection,
  EditorWrapper,
} from '@/features/editor/shared/editor-layout';
import { DeviceType } from '@/utils/definitions';
import { ExperienceSpinner } from '@/components/experience-spinner';

export default function EditorPage() {
  const { resources, loading } = useEditorLoader();

  return !loading && resources?.experience ? (
    <Editor resources={resources} />
  ) : (
    <EditorWrapper>
      <EditorDeviceSection>
        <DeviceMock>
          <div>
            <ExperienceSpinner color="#A9B5C2" size={4} thickness={0.5} />
          </div>
        </DeviceMock>
      </EditorDeviceSection>
      <EditorInspectorSection />
    </EditorWrapper>
  );
}

const DeviceMock = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 1rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: solid 0.2rem #dedede;
    background: rgba(244, 244, 244, 0.7);
    border-radius: 2.8rem;
    height: 100%;
    width: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `100%` : `40rem`};

    box-shadow: 0 18px 26px 0 rgba(157, 255, 221, 0.18),
      0 4px 20px 0 rgba(0, 0, 0, 0.12);
    overflow: hidden;
  }
`;
